/* @import "react-slideshow-image/dist/styles.css"; */
:root{
  --rainbow-gradient: linear-gradient(-90deg,#adfbda 0,#35c3ff 30%,#fda399 50%,#76d880 70%,#ebf38b 90%,#adfbda 100%);
}

html{
  /* overflow: hidden; */
  height: 100%;
}

body {
  /* height: 100vh; */
  /* width: 100vw; */
  font-size: 1.2rem; /*2.0 rem = 16px*/
  /* font-family: 'Times New Roman', Times, serif; */
  font-family:Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #eee8e8; */
  /* background-color: #ffffff; */
  /* background:
  linear-gradient(rgb(9, 2, 51), transparent),
  linear-gradient(to top, rgb(9, 2, 51), transparent),
  linear-gradient(to bottom, rgb(9, 2, 51), transparent); */
  /* background-color: #E7DFD9; */
  background-color: #CF6679;
  /* overflow: hidden; */
  margin: 0 !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  /* height: auto;
  width: auto; */
  overflow-y: hidden;
}

/* Layout */
.grid-container{
  display: flex;
  flex-direction: column;
  grid-template-areas:
      'header'
      'main'
      'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5em 1fr 5rem;
  height: 100%;
}
header{
  top: 0;
  grid-area: header;
  /* background-color: #6e9fe7; */
  /* background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent); */
  background-color: #F2F0E0;
  /* padding-top: 1vh; */
  padding-bottom: 1vh;
  height: 5vh;
}
main{
  grid-area: main;
  /* padding: 1vh; */
  /* padding-bottom: 8vh; */
  color: #fff;
  /* width: 100vw; */
  /* height: 95vh; */
}

@media (max-width: 90rem) {
  main{
    grid-area: main;
    /* padding: 1rem; */
    /* padding-top: 7rem; */
    /* height: 96vh; */
  }
  body {font-size: 85%;}
  .tabItem{
    flex: 0 0 min(20vw, 12rem) !important;
  }
}

footer{
  grid-area: footer;
  background-color: #6e9fe7;
  color: #FFF;
  /* left:0; */
  margin-top: 0;
  /* right:0; */
  bottom: 0;
  height: 4vh;
  /* width: 100vw; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 1000000;
  padding: 1rem;
  font-size: 1rem;
} 

/* Common classes */
.navigation-bar{
  position: fixed;
  width: 100%;
  z-index: 999; /*bring this to front*/
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  /*background: #0861a1;*/
  /* overflow: hidden; */ /*hide anything that wants to flow over the header*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .mobileNavBar{
  display: flex;
} */

.mobileNavBar > a{
  color: #FFF;
  text-decoration: none;
}

/*ul is display: block by default*/



.btn {
    display: block;
}
.menu{
    position: fixed;
    flex-direction: column;
    min-width: 400px;
    height: 100%;
    top: 0;
    right: -100%;
    padding: 80px 50px;
    transition: 0.5s;
    transition-property: right;
    z-index: 9998!important;
}

.active{
    right: 0;
}

.menu .closeBtn{
    position: absolute;
    top: 0;
    left: 0;
    margin: 25px;
}

div .menu a{
    display: block;
    font-size: 100px;
    margin: 20px;
    padding: 0 15px;
}
a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  transition: .2s;
  /* background-color: #ffffff;  */
  border-radius: 4rem;
  color: inherit;
  text-decoration: inherit;
}
/* .divHoldingLinkButton{
  padding: 2rem;
}
.linkButton{
  color: #ffffff;
  background-color: #6e9fe7;
  transition: .2s;
  padding: 1rem;
}
.linkButton:hover{
  color: #04115f;
  background-color:#ffffff; 
  transition: .2s;
  padding: 2rem;
  border-style: solid;
  border-color: #b4851e;  
}
.linkButton2{
  color: #ffffff;
  background-color: #6e9fe7;
  transition: .2s;
  padding: 0.1rem;
  border-radius: 1rem;
}
.linkButton2:hover{
  color: #04115f;
  background-color:#ffffff; 
  transition: .2s;
} */
/* .cyan-background{
  background-color: #6e9fe7;
  padding: 1rem 0rem 1rem 0rem;
} */

.search-background{
  background-color: #474242;
  padding: 1rem;
  border-radius: 0.1rem;
  box-shadow: 5px 6px 6px 2px #020202;
  width: 100%;
}

.searchField{
  border-radius: 2rem;
  background-color: #fff;
  width: 80%;
  height:1.9rem;
}

.searchBtn{
  height: 6rem;
  width: 10rem;
  background-color: #6e9fe7;
  transition: .2s;
}
.searchBtn:hover{
  height: 6rem;
  width: 10rem;
  background-color: #6e9fe7;
  transition: .2s;
  transform: scaleX(1.1);
  opacity: 1;
  color: #fff;
}
/* .searchInput{
  background-color: rgb(255, 238, 3);
  border-radius: 5rem;
  padding: 1rem;
  border-radius: 1rem;
  border: solid 0.25rem #000;
} */

.bold-text{
  font-weight: bold;
}
.yellow-text{
  color: #ffee00;
  background-color: #081a41;
  padding: 1rem;
  border-radius: 1rem;
  transition: .5s;
}
.yellow-text:hover{
  transition: .5s;
  color: #081a41;
  background-color: #ffee00;
  font-size: 3rem;
}
/* .product-Title{
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  background-color: #3f42eb;
  color: #fff;
  border-radius: 2rem;
  transition: .2s;
  
}
.product-Title:hover{
  padding: 1rem;
  transition: .2s;
  font-size: 4rem;
} */

::selection {
  color: #fff;
  background: #e48e0c;
}

h1{
  font-size: 2rem;
  padding: 1rem 0;
}
h2{
  font-size: 2rem;
  padding: 1rem 0;
}

p{
  word-wrap: break-word;
  /* display: table-cell; */
}

/*Header*/
/* header a{
  padding:  2rem;
  color: #000;
} */
header .navbar-button{
  display: none;
}
/* @media (max-width:45rem){
  header a{
    padding: 1rem;
    color: #fff;
    display: none;
  }
  header .navbar-button{
    display: block;
  }
  a.brand {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    display: block;
  }
  
  .product-Title{
    display: none;
  }
} */

/* .mobileNavBar{
  align-items: left;
  display: flex;
  top: 5rem;
  left: 0;
  transition: .5s;
  background-color: #6e9fe7;
  transform: translateY(0);
  animation: fadeIn .2s;
  flex-direction: column;
  position: fixed;
  z-index: 990;
  border-radius: 1rem;
  height: 90vh;
  width: 25%; 
  justify-content: top;
  display: flex;
  overflow: scroll;
  
  flex: 0 0 25rem;
  padding: 1rem;
}
.mobileNavBar > li{
  padding-top: 1rem;
  margin: 1rem;
} */


.floatingDiv{
  position: fixed;
  bottom: 3rem;
  left: 0;
  background-color: #6e9fe7;
  /* padding: 2rem; */
  border-radius: 2rem;
  transition: .3s;
  z-index: 25;
}
.floatingDiv.down{
  bottom: 0;
  left: 0;
}
.floatingDiv > button:hover{
  transition: .3s;
}
.floatingDiv > button:hover{
  filter: brightness(120%);
  transition: .3s;
}
.floatingDiv:hover{
  background-color: #fff;
  transition: .3s;
}

a.brand {
  color: #000;
  font-size: 3vw;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  padding: 1vw 1vh 1vw 1vh;
}
a.brand:hover{
  transition: .2s;
  transform: scale3d(2);
  color: #3cb4e4;
}
a.brand.selected{
  transition: .2s;
  transform: scale3d(2);
  color: #3cb4e4;
}
.row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.row.center{
  justify-content: center;
}

.row.left{
  justify-content: left;
}

.row.right{
  justify-content: right;
}

.row.top{
  align-items: flex-start;
}

.row.full{
  width: 100%;
}

.bottom{
  bottom: 0;
  position: fixed;
  left: 0;
}

.row-bottom{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  overflow-x: scroll;
  overflow-y: none;
}
.row.space{
  justify-content: space-around;
  width: 100%;
  /* backdrop-filter: blur(2rem); */
  padding: 0.2rem;
}

a.bottomNav{
  padding: 0rem !important;
}

.col-mini{
  flex: 0.1 0.1 0rem;
  padding: 1rem;
}
.col-mini.top{
  top: 0;
}

.col-0{
  flex: 1 1 0rem;
  padding: 1rem;
}

.col-1{
  flex: 1 1 25rem;
  padding: 1rem;
}

.col-2{
  flex: 2 1 50rem;
}

.col.full{
  flex: 2 1 50rem;
  width: 100%;
}

.min-30{
  min-width: 30rem;
}

ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  margin-top: 2rem;
}

button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #6e9fe7 solid;
  font-size: 1.6rem; /*16px*/
  font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #258ee4;
  cursor: pointer;
  color: #FFF;
  transition: .2s;
  appearance: button;
  /* background-color: #1899D6; */
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  /* white-space: nowrap; */
  width: auto;
}

button.primary::after{
  background-clip: padding-box;
  background-color: #063b53;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}



button.primary{
  background-color: #0b4275;
}

.primary{
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  max-width: 10rem;
}
.primary2{
  background-color: #184269;
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  max-width: 20rem;
}
.primary2:hover{
  background-color: #447eb4;
}

/*MUI Tables*/
.MuiButtonBase-root{
  width: 2rem;
}
.MuiButton-text{
  color: rgb(43, 0, 236) !important;
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive, .MuiSvgIcon-root {
  color: #fff !important;
}
.css-11mde6h-MuiPaper-root{
  background-color: transparent !important;
  color: #fff !important;
} 
.css-1ex1afd-MuiTableCell-root{
  color: #fff !important;
}
.tss-ynxllk-MUIDataTableFilter-root{
  background-color: #447eb4 !important;

}
.MuiTypography-root{
  color: #fff !important;
}
.MuiInput-root{
  color: #fff !important;
}
.MuiPaper-root{
  background-color: transparent !important;
}
.tss-djbknv-MUIDataTablePagination-navContainer{
  background-color: #0055ff;
}
.table tbody tr:nth-of-type(odd){
  background-color: #2a2a2b;
}

th{
  text-align: left;
  padding: 0.5rem;
  background-color: #052555 !important;
  color: #FFF !important;
}

td{
  text-align: left;
  padding: 0.9rem;
}

tbody tr:hover{
  background-color: #8db4ee;
  color: #fff;
  transition: 0.2s;
}
td:hover{
  background-color: #6e9fe7;
  color: #fff;
  transition: 0.2s;
}

/* .admin{
  background-color: #090c91;
  word-wrap: break-word;
  width: 100%;
  max-width: 10rem;
  white-space: normal;
  border-radius: 0%;
} */


/* button.admin::after{
  background-clip: padding-box;
  background-color: #3f42eb;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
} */

/* .admin:hover{
  background-color: #b4b5e9;
  color: #1a1ee6;
  border: solid #01036b;
  opacity: 1;
  transition: .2s;
  transform: scaleY(1.2);
}  */
 

button.primary:hover{
  background-color: antiquewhite;
  transition: .2s;
  /* color: #e48e0c; */
  opacity: 70%;
  transform: scaleY(1.2);
}

button.block{
  width: 100%;
}
button.tiny{
  font-size: 1.5rem;
}

.detailImage{
  width: 100%;
}

.cart-items-count{
  background-color: #a02021;
  color: #FFF;
  border-radius: 50%;
  /* padding: 0.2rem 0.7rem; */
  padding: 0.2rem;
  font-size: 1rem;
  /* margin-left: 0.1rem; */
  line-height: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translate(-0.5rem, 0rem);
  /* width:fit-content; */
}
.purple{
  color: rgb(47, 44, 206);
  background-color: #c0c0c0;
}

/*Images*/
img{
  border-radius: 0.5rem;
}
/* img:hover{
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
} */

img.medium{
  width: 100%;
  max-height: 30rem; 
  max-width: 30rem;
  object-fit: contain;
}
img.tiny{
  max-width: 20rem;
  width: 100%;
}

.img-container {
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  transition: 0.5s;
  opacity: 0;
  z-index: 4;
}

.img-container:hover > .centered{
  position: absolute; 
  display: block;
  transition: 0.5s;
  opacity: 1;
}

/*Card*/
.card{
  /* border: 0.1rem #a4ccd8 solid; */
  /* background-color: #d8d8d8; */
  /* background-color: rgb(178, 217, 226); */
  background-color:  #e9ecef;
  border-radius: 0.5rem;
  margin: 1rem;
  /* box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgb(99, 98, 98); */
  border-radius: 0.4rem;
}
.card-body {
  padding: 1rem;
  /* max-width: 10rem; */
}
.card-body > *{ /*apply directly to all children of .card-body*/
  margin-bottom: 0.5rem;
}

.card-body-limit{ /*for product card*/
  max-width: 20rem;
}

.card-user{
  max-width: 100rem;
  min-height: 50rem;
  transition: .2s;
  background-color: rgb(110, 169, 236);
  border: solid #000000 0.2rem;
}

.card-body-user-limit{
  max-width: 100rem;
  transition: .2s;
}
.card-user:hover{
  background-color: #3f42eb;
  color: #fff;
  transition: .2s;
}

.card-user:hover .card-body-user-limit > img {
  display: none;
  transition: .2s;
}

.card-user:hover .fa-check {
  color: #FFF;
  transition: .2s;
}

.card.library:hover{
  background-color: #fff;
  transition: .2s;
  /* border: solid 0.3rem #000; */
}

.card.library:hover > a > img{
  filter: brightness(120%); 
  transition: .2s;
}


/* .price{
  font-size: 2rem;
  color: #000;
} */

/*Rating*/
.rating span {
  color: #f0c040;
  margin: 0.1rem;
  font-size: 1rem;
}
.rating span:last-child{
  color: #203040;
}

/*Video Player*/
.videoPlayer{
  border-radius: 1%;
  width: 100%;
  background-color: bisque;
  height: 60rem;
}
/* Alert */
.success {
  color: #20a021;
}
.error{
  color: #a02021;
}
.alert{
  padding: 1rem;
  border: 0.1rem solid transparent;
  border: 0.5rem;
  border-radius: 1rem;
}
.alert-info{
  /* background-color: #023500; */
  background-color: #4f724d;
  color: #fff;
  text-align: center;
  width: 100%;
}
.alert-error{
  color: #fff;
  /* background-color: #a02021; */
  background-color: #ac4141;
}

.loadingBox{
  display: block !important;
}

/* Form */
form{
  max-width: 60rem;
  margin: 0 auto;
  backdrop-filter: blur(6rem) brightness(50%);;
  /* background-color: rgb(11, 55, 136); */
  color: rgb(184, 178, 178);
  /* box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgb(99, 98, 98); */
}

form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

form label{
  margin: 1rem 0;
  display: block;
}
.inputForm {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: center;
  backdrop-filter: none;
  background-color: rgb(9, 33, 70);
  border-radius: 2rem;
}

.inputField {
  min-width: 10rem;
  /* max-width: 20rem; */
  width: 100%;
  background-color: #fff;
  /* width: 100rem !important; */
  padding: 1rem;
}

.inputField.post{
  color: #fff;
  width: 100% !important;
  background-color: rgb(33, 34, 44);
}

/* .inputForm > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
} */

.invisibleForm{
  width: auto;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  backdrop-filter: none;
}

.editPostForm{
  max-width: 54rem;
  width: 100%;
  min-width: 10rem;
  /* margin: 0 auto; */
  padding: 1rem;
  backdrop-filter: none;
  /* position: fixed; */
}

.replySection{
  position: fixed;
  bottom: 0;
  /* transform: translate(39vw, 0); */
  /* background-color: #00273d; */
  margin: auto;
  /* opacity: 80%; */
  border-radius: 2rem;
  right: 3rem;
  
}

.postTitle{
  /* font-size: 5rem; */
  background-image: var(--rainbow-gradient,#fff);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  filter: drop-shadow(0 0 2rem #000);
  text-shadow: none!important;
  font-size: calc(1.625rem + 1.6vw);
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.postDetailPage{
  margin-bottom: 30rem;
}

.bottom-button-div{
  margin: 2rem;
}

.bottom-button-div > button{
  min-width: 10rem;
  width: 100%; 
}





input{
  padding: 2rem;
  font-size: 2rem;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 1rem;
}


.basic-slide {
  /* display: inline-block; */
  width: 100%;
  padding: 10px 0 10px 15px;
  font-family: "Open Sans", sans;
  font-weight: 400;
  /* color: #377D6A; */
  color:rgb(110, 169, 236);
  background: #efefef;
  border: 0;
  border-radius: 0%;
  outline: 0;
  text-indent: 70px;
  transition: all .3s ease-in-out;
}
.basic-slide::webkit-input-id {
  color: #efefef;
  text-indent: 0;
  font-weight: 300;
}
.basic-slide label {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 15px;
  text-shadow: 0 1px 0 rgba(19,74,70,.4);
  background: #7AB893;
  transition: all .3s ease-in-out;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.basic-slide:focus,
.basic-slide:active {
  /* color: #377D6A; */
  color: #000;
  text-indent: 0;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  transition: 1s;
  border-radius: 0%;
}
.basic-slide:focus,
.basic-slide:active label {
  /* transform: translateX(100%); */
  text-align: center;
  transition: 1s;
}

.swing {
  display: inline-block;
  width: 100%;
  padding: 10px 0 10px 15px;
  font-family: "Open Sans", sans;
  font-weight: 400;
  color: #377D6A;
  background: #efefef;
  border: 0;
  border-radius: 3px;
  outline: 0;
  text-indent: 60px;
  transition: all .3s ease-in-out;
}
.swing::-webkit-input-placeholder {
  color: #efefef;
  text-indent: 0;
  font-weight: 300;
}

.swing label {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 15px;
  text-shadow: 0 1px 0 rgba(19,74,70,.4);
  background: #7AB893;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transform-origin: 2px 2px;
  transform: rotate(0);
  animation: swing-back .4s 1 ease-in-out;
}
@keyframes swing {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(116deg);
  }
  40% {
    transform: rotate(60deg);
  }
  60% {
    transform: rotate(98deg);
  }
  80% {
    transform: rotate(76deg);
  }
  100% {
    transform: rotate(82deg);
  }
}
@keyframes swing-back {
  0% {
    transform: rotate(82deg);
  }
  100% {
    transform: rotate(0);
  }
}
.swing:focus,
.swing:active {
  color: #377D6A;
  text-indent: 0;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  
  
}
.swing:focus,
.swing:active::-webkit-input-placeholder {
  color: #aaa;
}
.swing:focus,
.swing:active>label {
  animation: swing 1.4s 1 ease-in-out;
  transform: rotate(82deg);
}

/* textarea{
  padding: 2rem;
  font-size: 2rem;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 1rem;
  border: solid #000 0.2rem;
  resize: none; 
  height: 20rem;
  word-wrap: break-word;
} */

.postText{
  padding: 2rem;
  width: 100%;
  /* max-width: 100rem; */
  font-size: 2rem;
  /* height: 100%;
  max-height: 100rem; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.topic{
  font-size: 3rem;
}

.postContent{
  background-color: #fff;
  border: #000;
  border-radius: 1rem;
  padding: 2rem;
}

.link-to-details{
  display: none;
}

.postBasic{
  margin: auto;
  /* max-width: 20rem; */
  transition: .2s;
  width: 90%;
  max-width: 60rem;
  /* border-radius: 2rem; */
  background-color: rgb(58, 56, 56);
  /* border-left-color: coral !important;
  border-right-color: coral !important; */
  border-left-color: rgb(255, 68, 0) !important;
  border-right-color: rgb(255, 68, 0) !important;
  border-top-color: rgb(58, 56, 56) !important;
  border-bottom-color: rgb(58, 56, 56) !important;
  border-style: solid;
  margin-bottom: 1rem;
  box-shadow: none !important;
  /* min-height: 10rem; */
}
.postBasic:hover{
  transition: .1s;
  background-color: rgb(36, 154, 233);
  /* transform: scale(120%) translateX(10%); */
  color: rgb(255, 255, 255) !important;
}
.postBasic.small{
  max-width: 20rem;
}

/* .postBasic:hover  .link-to-details{
  display: inline-block;
} */

/* .displayComment{
  width: min-content;
} */

.editComment{
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; /*make the textarea actually fits within div element. Really cool tho ngl*/

    width: 100%;
}

/* select {
  padding: 1rem;
  font-size: 2rem;
  width: 100%;
  border-radius: 1rem;
  font-family: 'Times New Roman', Times, serif;
  border: solid #000 0.2rem;
} */

select:hover {
  filter: brightness(120%);
  transition: .2s;
}

.box {
  top: 50%;
  left: 50%;
  border-radius: 15px;
  display:inline-block;
  overflow:hidden;
  width: 100%;
}

.box select {
  background-color: #0563af;
  color: white;
  padding: 12px;
  width: 100%;
  border: 1px solid #AAAAAA;
  font-size: 20px;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 0%;
}

.box::before {
  /* content: "\f13a"; */
  font-family: FontAwesome;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.5s;
}

.box select option {
  padding: 30px;
  transition: 0.5s;
}

.filterSelect{
  padding: 1rem;
  font-size: 2rem;
  width: 50%;
  border-radius: 0% !important;
  font-family: 'Times New Roman', Times, serif;
  border: solid #000 0.2rem;
}

select{
  padding: 1rem;
  font-size: 2rem;
  width: 50%;
  border-radius: 0% !important;
  font-family: 'Times New Roman', Times, serif;
  border: solid #000 0.2rem;

}

option{
  background-color: #09efff;
  color: #000000;
}



.tagInput{
  padding-left: 0rem;
  padding-right: 0rem;
  width: 100%;
}

.comment{
  padding: 2rem;
  font-size: 2rem;
  font-family: 'Times New Roman', Times, serif;
  width: 90%;
  height: 20rem;
}

.bottom-right-button-div{
  float: left;
}

.bottom-left-button-div{
  float: right;
}

/* Drop down */
.dropDown{
  display:inline-block;
  position: relative;
}


.dropDown-content{
  position: absolute;
  display: none;
  right: 0;
  min-width: 20rem;
  padding: 1rem;
  z-index: 999;
  /* background-color: #2485f3; */
  background:
    linear-gradient(#0cbaff, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
  text-align: right;
  transition: .2s;
}
.dropDown-content.small{
  width: 2rem; 
  margin: none;
  top:0;
  text-align: left;
  min-width: 2rem;
  transform: translate(0rem, -2rem);
  animation: fadeIn .9s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: .2s;
  }
  to { 
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: .2s;
  }
}

.dropDown:hover .dropDown-content{
  transform: translateY(0);
  animation: fadeIn .2s;
  display: block;
}
.dropDown:hover .dropDown-content.small{
  transform: translateY(0);
  animation: fadeIn .9s;
  display: block;
}

/* Check Out Steps */
.checkout-steps > div{
  border-top: 0.5rem #bad470 solid;
  color: #bad470;
  flex: 1;
  padding: 1rem;
}

.checkout-steps > div.active{
  border-top-color: #ecb247;
  color: #ecb247;
}


/*  table  */
table{
  width: 100%;
  border: 0.1rem solid #000;
  border-collapse: collapse; 
}
th{
  text-align: left;
  padding: 0.5rem;
  background-color: #052555;
  color: #FFF;
}
tr {
  border: 0.1rem solid #000;
}

td {
  padding: 0.9rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table tbody tr:nth-of-type(odd){
  background-color: #2a2a2b;
}

.table th{
  text-align: left;
  padding: 0.5rem;
  background-color: #052555;
  color: #FFF;
}

.table td{
  text-align: left;
  padding: 0.9rem;
}

.table button{
  margin: 0 0.2rem;
}

.table tbody tr:hover{
  background-color: #8db4ee;
  color: #fff;
  transition: 0.2s;
}
.table td:hover{
  background-color: #6e9fe7;
  color: #fff;
  transition: 0.2s;
}

/* Profile menu */
.nav-menu{
  display: flex;
  /* max-width: 50rem; */
  justify-content: center;
  /* position: fixed; */
  
}
.nav-menu-item {
  z-index: 200;
  color:rgb(255, 255, 255) !important;
  /* border: 0.1rem #c0c0c0 solid; */
  background-color: #074681;
  /* border-radius: 0.5rem; */
  /* margin: 1rem; */
  padding: 1rem;
  width: min(15vw, 20rem);
  color:rgb(139, 110, 110);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
}
.nav-menu-item:active {
  color:#074681 !important;
  background-color: #fff;
}
.nav-menu-item.selected {
  color:#074681 !important;
  background-color: #fff;
}
.nav-menu-item > p {
  font-size: 0rem;
}

/* icon */
/* .fa{
  font-size: 1rem;
} */

.bigger-icon{
  font-size: 5rem;
}

.fa-reddit{
  color: #ca7d17;
  width: 100%;
}

.fa-github{
  color: #000;
  width: 100%;

}

.fa-youtube{
  color: #a02020;
  width: 100%;
}

.fa-discord{
  color:rgb(117, 115, 189);
  width: 100%;
}

.fa-instagram{
  color: #D51864;
  width: 100%;
}

.fa-check{
  color: #3f42eb;
}

.blender-logo{
  width: 100%;
  max-width: 4rem;
}

.bold{
  font-weight: bolder;
}

.fa-circle{
  font-size: 1rem;
}

.fa-bars{
  font-size: 2rem;
  padding-right: 1rem;
}

.fa-xmark{
  font-size: 2rem;
  padding-right: 1rem;
}

.online{
  color: #00ff6a;
}

.offline{
  color: #8a9b91;
}

.fa-phone {
  /* font-size: 4rem; */
  color: #00ff6a;
}

.fa-fire {
  font-size: 4rem;
  color: #d86519;
}

.fa-circle-o {
  font-size: 4rem;
  color: #ffae00;
}

.fa-globe{
  font-size: 4rem;
  color: #04374b;
}

.fa-comment{
  color: rgb(214, 170, 24);
}

.fa-money{
  color: rgb(6, 92, 18);
  background-color: #7ead86;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.fa-clock{
  color: rgb(226, 194, 135);
  background-color: #0d1c2b;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.fa-medal{
  color: rgb(255, 166, 0);
  background-color: #313147;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.support-div{
  color: #fff;
  background-color: #3feb87;
  border-radius: 1rem;
  padding: 1rem;
  margin-left: 1rem;
}

.general-div{
  margin-left: 1rem;
  background-color: #0f4c5f;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem;
}

.offtopic-div{
  margin-left: 1rem;
  background-color: #916d0a;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem;
}

.news-div{
  margin-left: 1rem;
  background-color: #e94f34;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem;
}


/* React-Slideshow */
/* .slide-container{
  width: 100%;
  max-width: 40rem;
  margin: auto;
}

.each-slide{
  display: block;
}

.slide-image{
  width: 100%;
  max-width: 80rem;
  display: block;
} */

/* HOME PAGE  */
.homeDiv{
  width: 100%;
  height: 100vh;
  transition: .5s;
  border-radius: 2rem;
}

.homeDiv:hover{
  transition: .5s;
  
}

/* Glitch */
.glitch-div{
  animation: glitch 1s linear infinite;
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.glitch-div:before,
.glitch-div:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitch-div:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.glitch-div:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}


.effect {
  font-size: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-color: transparent;
  background-size: cover;
  /* background-image: url('https://scontent.fsgn12-1.fna.fbcdn.net/v/t1.15752-9/249249150_1299722487141140_7988736997844511473_n.png?_nc_cat=110&ccb=1-5&_nc_sid=ae9488&_nc_ohc=ZG9DmKHgWDoAX-zmc-k&_nc_ht=scontent.fsgn12-1.fna&oh=03_AVIKxFFO8nvZbOkEhBQP_haQKoZJ241U5YSVX6NXwq2S1Q&oe=61DDF77A'); */
}

.effect-img {
  opacity: 0;
  display: block;
}

.effect-caption {
  position: absolute;
  pointer-events: none;
  top: 45%;
  width: 100%;
  font-size: 10%;
  color: #5000aa;
  text-shadow: 2px 2px white;
  transition: opacity .2s ease-out;
  opacity: 1;
  z-index: 5;
}

.effect:after,
.effect:before {
  content: "";
  z-index: 3;
  display: block;
  top: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  transition: all 0.2s ease-out;
}

.effect:hover .effect-caption {
  opacity: 0;
}

.effect-glitch:hover:before {
  clip: rect(28%, 100%, 38%, 0%);
  margin-left: -1%;
  margin-top: .1%;
  filter: brightness(150%) contrast(2);
  animation: shake-glitch--1 1.1s ease-in-out infinite;
}

.effect-glitch:hover:after {
  clip: rect(78%, 100%, 88%, 0%);
  margin-left: 1vh;
  filter: brightness(150%) contrast(2) hue-rotate(20deg);
  animation: shake-glitch--2 1.4s ease-in-out infinite;
}

@keyframes shake-glitch--1 {
  28% {
    clip: rect(28%, 100%, 38%, 0%);
    margin-right: 1%;
  }
  30% {
    clip: rect(26%, 100%, 36%, 10%);
    margin-left: 0.1%;
  }
  32% {
    clip: rect(28%, 100%, 38%, 0%);
    margin-right: 1%;
  }
  34% {
    clip: rect(26%, 100%, 36%, 10%);
    margin-left: 0.1%;
  }
  36% {
    clip: rect(28%, 100%, 38%, 0%);
    margin-right: 1%;
  }
}

@keyframes shake-glitch--2 {
  68% {
    clip: rect(78%, 100%, 88%, 0%);
    margin-right: 1%;
  }
  70% {
    clip: rect(56%, 100%, 56%, 10%);
    margin-right: 0.1%;
  }
  72% {
    clip: rect(78%, 100%, 88%, 0%);
    margin-right: 1%;
  }
  74% {
    clip: rect(56%, 100%, 56%, 10%);
    margin-right: 0.1%;
  }
  76% {
    clip: rect(78%, 100%, 88%, 0%);
    margin-right: 1%;
  }
}

::selection {
  color: rgb(255, 255, 255);
  background: rgb(0, 162, 255);
}

/* .order-item{
  transition: 0.2s;
}

.order-item:hover{
  transition: 0.2s;
  transform: scale(110%);
} */


/* .user-name-display{
  color: rgb(8, 71, 112);
  font-style: bold;
  transition: 0.1s;
}

.user-name-display:hover{
  transition: 0.1s;
  filter: brightness(120%);
  opacity: 0.5;
} */

.rating-css {
  height: 5rem;
  width: 90%;
  /* background: #101012; */
  padding: 20px;
}
.rating-css div {
  color: #ffe400;
  font-size: 30px;
  font-family: sans-serif;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 0;
}
.rating-css input {
  display: none;
}
.rating-css input + label {
  font-size: 60px;
  text-shadow: 1px 1px 0 #ffe400;
  cursor: pointer;
}
.rating-css input:checked + label ~ label {
  color: #838383;
}
.rating-css label:active {
  transform: scale(0.8);
  transition: 0.3s ease;
}

pre {
  background-color: rgb(216, 213, 213) !important;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
}

code {
  font-size: 1.2rem !important;
  background-color: rgb(216, 213, 213) !important;
  border-radius: 0.5rem !important;
}

/*comment in code block*/
.comment{
  font-size: 1.2rem !important;
}

.code-block > div > button{
  width: 10rem !important;
}

.scroller{
  overflow-y: scroll;
  width: 90rem;
  padding-left: 25%;
  /* word-break: break-all; */
  word-break: break-word;
}

.Editor{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 1rem !important;
  font-size: 1.2rem;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 1rem;
  resize: none; 
  height: auto;
  /* word-wrap: break-word; */
  /* max-width: 50rem !important; */
  min-width: 10rem !important;
  width: 100%;
  background-color: rgb(33, 34, 44);
  /* color: #fff; */
  /* word-break: break-all; */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  position: relative;
}
.Editor.readonly{
  padding: 0rem;
}

.ProseMirror{
  background-color: rgb(33, 34, 44);
  /* background:
    linear-gradient(rgb(21, 13, 133), transparent),
    linear-gradient(to top, rgb(9, 2, 51), transparent),
    linear-gradient(to bottom, rgb(9, 2, 51), transparent); */
  color: #fff;
}

/* .sc-fnykZs{
  background-color: rgb(8, 13, 70) !important;
  color: #fff;
} */

.buttonLink{
  background-color: transparent;
  color: #000;
}

.buttonLink:hover{
  color: #fff;
  background-color: #1978c5;
}

button > p{
  word-break: break-word;
}

.Select{
  color: #0074D9;
  padding: 1rem;
  font-size: 2rem;
  /* width: 50%; */
  width: 100%;
  border-radius: 1rem;
  font-family: 'Times New Roman', Times, serif;
  border: solid #000 0.2rem;

}

.react-dropdown-select-parent-div{
  border-style: solid;
  border: 2rem !important; 
  border-left-color: #1978c5 !important; 
}

.react-dropdown-select{
  /* min-width: 10rem !important;  */
  border-style: solid;
  min-height: 4rem !important; 
  font-size: 1.5rem;
  /* max-width: 60rem; */
  border-radius: 1rem !important;
}
.react-dropdown-select {
  background-color: rgb(33, 34, 44) !important;
  width: min(45vw, 35rem) !important;
}

.react-dropdown-select-item{
  min-width: 10rem !important;
  border-radius: 1rem;
  padding: 0.1rem;
  /* max-width: 35rem; */
  width: 100% !important; 
}

.react-dropdown-select-dropdown{
  background-color: transparent !important;
  border: 0rem solid !important;
}

.textButton{
  color: #000;  
  border-top: #090c91 0.3rem;
  border-bottom: #090c91 0.3rem;
  border-radius: 0%;
  word-wrap: break-word;
  white-space: normal;
  border-style: solid;
  background-color: #FFF;
}

.child{
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border-style: solid;
  border-left: #6466bb 0.3rem;
  border-right: #6466bb 0.3rem;
  border-radius: 0%;
  word-wrap: break-word;
  white-space: normal;
  border-style: solid;
  background-color: #FFF;
  margin-top: 1rem;
  max-width: 10rem;
}

.child:hover{
  transition: 0.2s;
  color: rgb(255, 255, 255);
  background-color: #1978c5;
}

.textButton:hover{
  transition: 0.2s;
  color: rgb(255, 255, 255);
  background-color: #091868;
}

.postHere{
  background-color: #ffffff;
  margin: 0rem;
  padding: 5rem;
  width: 100%;
  max-width: 60rem;
  border-style: solid;
  border-left-color: #09efff;
  border-right-color: #09efff;
  border-top: #fff;
  border-bottom: #fff;
  background-color: rgb(33, 34, 44);
}
.postHere > form{
  backdrop-filter: none;
}




/* Rasa Webchat Window */
/* .css-1kgb40s{
  width: 50% !important;
} */

/* Rasa Webchat Popup */
/* .css-1kgb40s div{
  width: 100vh !important;
}

.css-ixhvoz{
  width: 100vh !important;

}

.css-pemi2b{
  width: 100vh !important;

}

.css-1c77470{
  width: 100vh !important;

} */

/*text-size*/
.css-8f4u10 {
  font-size: 1rem !important;
}

.css-jgse21{
  width: 3rem !important;
  height: 3rem !important;
}

.css-jgse21 > img{
  width: 3rem !important;
  height: 3rem !important;
}

/*rasa chat widget placeholder*/
/* .css-w3c9za{} */

/*img class sent by bot*/
/* .css-11ealgn:active {
  left: 50%;
  top: 50%;
  position: fixed !important;
  transform: scale(1);
} */

/* .rw-image-frame{} */

/* conversation container */
.rw-header {
  background-color: #0074D9 !important;
}

.rw-launcher{
  background-color: #0074D9 !important;
}

.rw-client{
  background-color: #0074D9 !important;
}

/*rasa chat widget textarea*/
.rw-sender{
  width: 100% !important;
}
.rw-send{
  background-color: #0074D9 !important;
  max-width: 5rem;
  
}
.rw-new-message{
  width: 100% !important;
}
.rw-messages-container{
  background:
    linear-gradient(rgb(3, 95, 216), transparent),
    linear-gradient(to top, rgb(3, 33, 63), transparent),
    linear-gradient(to bottom, rgb(3, 50, 88), transparent);
}
.rw-conversation-container{
  background: transparent !important;
}
.rw-launcher{
  box-shadow: 5px 6px 6px 2px #020202 !important;
}

.popupChatBox{
  transform: translate(-50%, -50%);
  z-index: 990;
  padding: 3vh 3vh 3vh 3vh;
  color: #fff;
  
  font-size: 3vh;
  
  position: fixed;
  opacity: 100%;
}

.popupChatBox.info{
  background-color:rgb(43, 110, 197);
  padding: 0vh 3vh 3vh 3vh;
  width: 60%;
  height: 60%;
  left: 50%;
  top: 50%;
  background:
    linear-gradient(rgb(3, 95, 216), transparent),
    linear-gradient(to top, rgb(3, 33, 63), transparent),
    linear-gradient(to bottom, rgb(3, 50, 88), transparent);
  border-radius: 0vh;
  animation: zoomPopup 0.7s;
  /* box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgb(0, 0, 0); */
  overflow-y: auto;
  border: 2rem solid #0074D9;
  border-radius: 2rem;
}

.popupChatBox.error{
  background-color:rgb(182, 70, 62);
}

/* .popup.option{
  background-color:rgb(182, 70, 62) !important;
  width: 10vh;
  height: 10vh;
  right: 35vh !important;
  font-size: 2vh;
  border-radius: 3rem;
  text-align: center;
  animation: zoomPopupX 2s;
} */


.popupCoverupChatBox{
  width: 100%;
  height: 315vh;
  background-color: #000000;
  position: absolute;
  opacity: 50%;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
  z-index: 100;
}

.chatBox{
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
  /* padding-left: 25%; */
  /* word-break: break-all; */
  word-break: break-word;
}

.bot-text{
  background-color: #F4F7F9;
  color: #000;
  padding: 1rem;
  border-radius: 1rem;
}

.user-text{
  background-color: #0074D9;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
}

.padding-text{
  padding: 0.5rem
}

/* .chatBoxTitleBar{
  display: flex;
  height: 2rem;
  background-color: #00308f;
  position: fixed;
  z-index: 991;
  top: 10rem;
  width: 64%;
} */

@keyframes zoomPopup {
  0%{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1); 
    opacity: 50%; 
  }
  50%{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.4);  
    opacity: 80%;
  }
}

@keyframes zoomPopupX {
  0%{
    transform: translate(-50%, -50%) scale(1); 
    opacity: 50%; 
  }
  50%{
    transform: translate(-50%, -50%) scale(1.4);  
    opacity: 80%;
  }
}

@keyframes slideUpPopup {
  from { bottom: 0; }
  to {
    top: 50%;
    left: 50%;
    transition: 0.5s;
    transform: translateX(50%);
  }
}

.rounded-div{
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
}


.user-timestamp{
  color:rgb(255, 255, 255);
  font-size: 1rem !important;
}

.bot-timestamp{
  color:rgb(255, 255, 255);
  font-size: 1rem !important;
}

.sessionStart{
  color: #fff;
  visibility: visible;
}

.confirmBtn{
  border: 0rem;
  /* background-color: rgb(4, 255, 129); */
  padding: 2vh 4vh 2vh 4vh;
  border-radius: 2vh;
  font: 3vh solid arial;
  margin-top: 2vh;
  width: 20vh;
}

.xClose:hover{
  transition: 0.2s;
  background-color: rgb(78, 5, 3);
  color: #353535;
  box-shadow: #353535 2rem;
}

.xClose{
  text-align: center;
  background-color: rgb(255, 0, 0) !important;
  color: #ffffff;
  padding: 2vh;
  border-radius: 5vh;
  font: 2vh solid;
  transform: translateY(-50vh);
  right: 20rem;
  width: 8vh;
  height: 8vh;
  box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgb(0, 0, 0);
  z-index: 992;
  position: fixed;
}

.confirmBtn.info{
  background-color: rgb(4, 255, 129);
  color: #fff;
}

.confirmBtn.info:hover{
  background-color: rgb(1, 146, 74);
  color: #fff;
}

.chatImg{
  width: 50vh;
}

.pfp{
  top: 0;
}

.interactiveUsername{
  /* color: #01036b; */
  color: #fff;
  width:fit-content;
  /* font-weight: 700; */
}

.interactiveUsername:hover{
  color:#0074D9;
  /* text-shadow: 0.05rem 0.05rem; */
  transition: 1s;
  /* font-weight: 700; */
}

.userHoverInfo{
  visibility: hidden;
  color: #fff;
  width: auto;
  height: auto;
  min-width: 14rem;
  font-size: 1.2rem;
  transition: 1s;
  background-color: #04374b;
  border-radius: 1rem;
  position: absolute;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
}

.userHoverInfoContent{
  backdrop-filter: brightness(60%) blur(0.6rem);
  padding: 1rem;
  border-radius: 1rem;
}

.interactiveUsername:hover .userHoverInfo{
  opacity: 100;
  color: #fff;
  visibility: visible;
  width: auto;
  height: auto;
  transition: 0.5s;
  background-color: #04374b;
  background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  border-radius: 1rem;
  /* position: absolute; */
  z-index: 10;
  padding: 1rem;
  box-shadow: #000 2rem;
  font-size: 1.2rem;
}

.interactiveUsername:hover .userHoverInfo.toRight{
  opacity: 100;
  color: #fff;
  visibility: visible;
  width: auto;
  height: auto;
  transition: 0.5s;
  background-color: #04374b;
  background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  border-radius: 1rem;
  position: fixed;
  z-index: 10;
  padding: 1rem;
  box-shadow: #000 2rem;
  transform: translate(10rem, -3rem);
  font-size: 1.2rem;
}

.fa-mars{
  color: #5e60dd;
}

.fa-venus{
  color: rgb(125, 66, 236);
}

.fa-birthday-cake{
  color: rgb(228, 116, 42);
}

.fa-brain{
  color: rgb(191, 142, 197);
}

a[href], input[type='submit'], input[type='image'], label[for], select, button, .interactiveUsername, .interactiveText, .accumulate, .tagSelectItem, .clickableIcon, .postBasic, .clickable {
  cursor: pointer;
}
.interactiveText{
  /* color: #5052d3; */
  color: #fff;
  transition: 0.2s;
}
.interactiveText.headerBar{
  color: #ffffff;
  transition: 0.2s;
}
.interactiveText.headerBar:hover{
  color: rgb(0, 0, 0);
  backdrop-filter: brightness(110%);
  transition: 0.2s;
}
.interactiveText.noHighlight:hover{
  color: #0059FF;
  /* background-color: #0059FF; */
  font-size: 1.5rem;
  backdrop-filter: brightness(110%);
  transition: 0.2s;
}
.interactiveText:hover{
  /* color: #01036b; */
  transition: 0.2s;
}
.userProfilePicture{
  display: flex;
  justify-content: center;
  /* background:
    linear-gradient(#0055ff, transparent),
    linear-gradient(to top, transparent, transparent),
    linear-gradient(to bottom, transparent, transparent); */
  background-size: 50% auto;
  padding: 2vh;
  align-items: flex-end;
}
.avatarCircle{
  border-radius: 100%;
  width: 215px;
  height: 215px;
  background:
    linear-gradient(#441cd6, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  /* line-height: 20vh; */
  text-align: center;
  font-size: 10vh;
  color: #fff;
  border: 2rem #0055ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-size: "cover";
  background-position: "center center";
  position: relative;
}

.avatarSquare{
  border-radius: 20%;
  width: 1rem !important;
  height: 1rem !important;
  background:
    linear-gradient(#441cd6, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  line-height: 3vh;
  text-align: center;
  font-size: 2vh;
  color: #fff;
  border: 2rem #0055ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  padding: 1rem;
  margin-right: 1rem;
  /* display:inline-block;
  display: flex; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-size: "cover";
}

.fa-envelope{
  color: rgb(228, 217, 183);
}

.fa-tasks{
  color: rgb(61, 43, 15)
}

.fa-home{
  color:#0055ff;
}

.avatarName{
  /* z-index: 1000; */
  font-size: 2rem;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.avatarUsername{
  /* z-index: 1000; */
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.mainprofile{
  /* background-color: #2f3136; */
  /* background-color: #fff; */
  /* background:
    linear-gradient(to top, #0046f8, #fff); */
  /* background-image: url("https://cdn.wallpapersafari.com/68/2/H80mcI.jpg"); */
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: 100% 100%; */
  /* background-size: cover; */
  /*background property in css is overridden by ReactJS Inline css*/
  /* background:
    linear-gradient(rgb(21, 13, 133), transparent),
    linear-gradient(to top, rgb(9, 2, 51), transparent),
    linear-gradient(to bottom, rgb(9, 2, 51), transparent); */
    /* background-color: #121212; */
  height: 89vh;
  padding-top: 5vh;
  overflow-y: auto;
}

.navTab{
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* padding: 1rem; */
  /* overflow-x: scroll; */
  /* width: 100vh; */
  /* opacity: 50%; */
  /* overflow: auto; */
  /* white-space: nowrap;
  box-sizing: border-box; */

  text-align: left;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  /* border: solid 0.2rem rgb(255, 255, 255); */
  /* justify-content: center; */

}

.navTab.footer{
  
}

.footerItem{

}

.tabItem{
  /* display: inline-block; */
  /* width: 100vh; */
  /* width: 20rem; */
  /* background-color: #ffffff; */
  /* background: linear-gradient(#fff, #fff, transparent); */
  padding: 2rem;
  /* padding-top: 2rem; */
  /* padding-left: 2rem; */
  /* padding-right: 2rem; */
  box-shadow: 0 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(0.6rem); */
  backdrop-filter: blur(4rem);
  color: #fff;
  /* word-break: keep-all; */

  flex: 0 0 min(40vw, 20rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  scroll-snap-align: start;
  flex-direction: column;
  transition: 0.2s;
  border-right: solid 0.1rem #fff;
}
.album{
  border-right: 0px;
  /* background-color: #DFD45A; */
  height: auto;
}
.tabLine{
  width: 100%;
  height: 0.3rem;
  background-color: rgb(0, 89, 255);
  margin-top: 2rem;
  display: flex;
}
.emptyLine{
  width: 100%;
  height: 0.3rem;
  margin-top: 2rem;
  display: flex;
}
/* hide scrollbar but allow scrolling */
main {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  /* overflow-y: scroll;  */
}
::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.navTab {
  -ms-overflow-style: auto; /* for Internet Explorer, Edge */
  scrollbar-width: auto; /* for Firefox */
  overflow-x: scroll; 
}
.navTab::-webkit-scrollbar {
  display: block; /* for Chrome, Safari, and Opera */
}



/* .infoTab{
  width: 100%;
  text-align: center;
}

.statTab{
  width: 100%;
  text-align: center;
} */
@media (max-width: 60rem) {
  .postDetail{
    /* margin: auto; */
    /* max-width: 20rem; */
    transition: .2s;
    width: 100%;
    /* border-radius: 2rem; */
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgb(33, 34, 44); */
    /* border-top-color: coral !important;
    border-bottom-color: coral !important; */
    /* border-top-color: rgb(255, 255, 255) !important;
    border-bottom-color: rgb(255, 255, 255) !important; */
    /* border-left-color: rgb(33, 34, 44) !important;
    border-right-color: rgb(33, 34, 44) !important; */
    /* border-style: solid; */
    margin-bottom: 1rem;
    color: #fff;
    background: transparent;
  }
}
@media (min-width: 60rem){
  .postDetail{
    /* margin: auto; */
    /* max-width: 20rem; */
    transition: .2s;
    width: 60%;
    /* border-radius: 2rem; */
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgb(33, 34, 44); */
    /* border-top-color: coral !important;
    border-bottom-color: coral !important; */
    /* border-top-color: rgb(255, 255, 255) !important;
    border-bottom-color: rgb(255, 255, 255) !important; */
    /* border-left-color: rgb(33, 34, 44) !important;
    border-right-color: rgb(33, 34, 44) !important; */
    /* border-style: solid; */
    margin-bottom: 1rem;
    color: #fff;
    background: transparent;

  }
}

.professional{
  border-color: rgb(111, 7, 172);
  border: 2rem solid rgb(111, 7, 172);
  border-radius: 1rem;
  padding-right: 1rem;
  background-color: rgb(111, 7, 172);

}
.contributer{
  border-color: rgb(7, 73, 128);
  border: 2rem solid rgb(7, 73, 128);
  border-radius: 1rem;
  padding-right: 1rem;
  background-color: rgb(7, 73, 128);

}
.admin{
  background-color: rgb(38, 11, 190);
  /* background:
    linear-gradient(#2b248f, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent); */
  border: 2rem solid rgb(38, 11, 190);
  border-radius: 1rem;
  padding-right: 1rem;
}


.quote{
  font-size: 2rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color: rgb(255, 255, 255);
  font: italic;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  backdrop-filter: blur(6rem);
  /* background-color: #000; */
}

.avatarCheckmark{
  background-color: rgb(0, 255, 136);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  position: relative;
  /* margin-left: 80px;
  margin-top: 130px; */
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  /* transform: translate(10rem, 7rem); */
  transform: translate(10rem, 10rem);
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}
.avatarCheckmark.left{
  border-radius: 20%;
  transform: translate(0rem, 10rem);
  width: 4rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  background-color: rgb(223, 148, 9);
  display: flex;
  justify-content: space-between;
}
.avatarCheckmark.info{
  background-color: rgb(55, 58, 56);
}
.checkmarkHoverInfo{
  visibility: hidden;
  /* width: auto;
  height: auto; */
  transition: 0.5s;
  background-color: #F2F0E0;
  border-radius: 1rem;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-size: 1rem;
  /* max-width: 10rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 2 rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent); */
  color: #000;
  border-radius: 1rem;
  z-index: 100;
  padding: 1rem;
}
.avatarCheckmark:hover + .checkmarkHoverInfo, .checkmarkHoverInfo:hover>.fa-check + .checkmarkHoverInfo{
  opacity: 100;
  visibility: visible;
  transition: 0.1s;
  transform: translate(0rem, 6rem);
}
.checkmarkHoverInfo:hover {
  opacity: 100;
  visibility: visible;
  transition: 0.1s;
  transform: translate(0rem, 6rem);
}


.creatorMark{
  visibility: visible !important;
  /* width: auto;
  height: auto; */
  transition: 0.5s;
  background-color: #F2F0E0;
  border-radius: 1rem;
  position: absolute;
  opacity: 100 !important;
  cursor: pointer;
  font-size: 1rem;
  /* max-width: 10rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent); */
  color: #000;
  border-radius: 1rem;
  z-index: 100;
  padding: 1rem;
  transform: translate(0rem, -16rem);
}
.avatarCircle:hover + .creatorMark {
  opacity: 100;
  visibility: visible;
  transition: 0.1s;
  transform: translate(0rem, -10rem);
}
.creatorMark:hover{
  opacity: 100;
  visibility: visible;
  transition: 0.1s;
  transform: translate(0rem, -10rem);
}

.onlineIcon{
  background-color: rgb(0, 255, 136);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  position: absolute;
  margin-left: 16vh;
  margin-top: 15vh;
  font-size: 2rem;
}

.offlineIcon{
  background-color: rgb(59, 61, 60);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  position: absolute;
  margin-left: 16vh;
  margin-top: 15vh;
  font-size: 2rem;
}

.userBackground{
  position: absolute;
  width: 100%;
  z-index: -1;
}



.react-dropdown-select-item{
  background-color: rgb(33, 34, 44) !important;
}

.contentTitle{
  border-bottom: 0.2rem solid rgb(236, 142, 0);
}

.displayImage{
  width: 100%;
}

.list {
  color: inherit;
  text-decoration: inherit;
}
.list:hover {
  transition: .2s;
  /* background-color: #ffffff;  */
  border-radius: 4rem;
  color: inherit;
  text-decoration: inherit;
}
.gallery{
  overflow-y: scroll;
  height: 50rem;       
  overflow-y: auto;    
  overflow-x: hidden;
  width: 100%;
  background-color: #CF6679;
}
.showcase {
  max-width: 50vw;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.viewingImageDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(1rem);
}
.viewingImageDiv > img {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}
.scrollInanimatedText {
  justify-content: right;
}
.scrollAnimatedText > span{
  /* transform: translate(10vh, 0vh); */
  position: absolute;
  top: 0;
  animation: verticalSlideTextBiggerFont 6s infinite;
  font-weight: bold;
  white-space:nowrap;
  /* max-width: 50vw; */
}
.scrollAnimatedText {
  height: 13vh;
  line-height: 13vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 70vw;
}
/* @keyframes verticalSlideText {
  0% {
    top: 0;
  }
  25% {
    top: -6rem;
  }
  50% {
    top: -12rem;
  }
  75% {
    top: -18rem;
  }
} */
@keyframes verticalSlideTextBiggerFont {
  0% {
    top: 0;
  }
  5%{
    top: 0;
  }
  20% {
    top: -13vh;
  }
  35% {
    top: -26vh;
  }
  50% {
    top: -39vh;
  }
  65%{
    top: -52vh;
  }
  80%{
    top: -65vh;
  }
  95%{
    top: -78vh;
  }
  110%{
    top: -84vh;
  }
  111%{
    top: 0;
  }
}
@keyframes verticalSlideText {
  0% {
    top: 0;
  }
  20% {
    top: -6vh;
  }
  40% {
    top: -12vh;
  }
  60% {
    top: -18vh;
  }
  80%{
    top: -24vh;
  }
}
/*HOME PAGE ANIMATED DIVS*/
.scrollAnimatedTextDiv{
  font-size: 4vw;
  min-width: 50vw;
  display: flex;
  justify-content: center;
}
.coffeeMachineTop {
  width: 15rem;
  height: 5rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background: rgb(68, 47, 47);
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 101;
}
.coffeeMachineBody {
  width: 15rem;
  height: 10rem;
  background: rgb(82, 68, 68);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.coffeeMachinePipe {
  width: 5rem;
  height: 7rem;
  border-radius: 2rem;
  background: rgb(68, 47, 47);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  z-index: 101;
}
.coffeeMachinePipe2 {
  width: 1rem;
  height: 2rem;
  /* border-radius: 2rem; */
  background: rgb(0, 0, 0);
  position: absolute;
  transform: translateY(1rem);
  z-index: 101;
}
.coffeeMachineBottom {
  width: 15rem;
  height: 5rem;
  /* border-radius: 2rem; */
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background: rgb(110, 106, 106);
  position: absolute;
  transform: translateY(1rem);
}
.coffeeMachineButton{
  width: 2rem;
  height: 2rem;
  background-color: #0055ff;
  border-radius: 5rem;
  position: absolute;
  z-index: 101;
  transform: translate(5rem, 1rem);
}
.coffeeMachineButton2{
  width: 1rem;
  height: 1rem;
  background-color: #748fc7;
  border-radius: 5rem;
  position: absolute;
  z-index: 101;
  transform: translate(6rem, 1rem);
}
.coffeeFluid{
  width: 0.3rem;
  height: 4rem;
  animation: coffeePour 5s infinite;
  background-color: rgb(53, 38, 38);
  position: absolute;
  z-index: 100;
}
@keyframes coffeePour {
  0% {
    transform: translate(0rem, -5rem);
  }
  25% {
    transform: translate(0rem, -5rem);
  }
  50% {
    transform: translate(0rem, 0rem);
  }
  75% {
    transform: translate(0rem, 0rem);
  }
  100%{
    transform: translate(0rem, 4rem);
  }
}
.coffeeCup{
  width: 5rem;
  height: 4rem;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  background-color: #b89f9f;
  position: absolute;
  transform: translate(0rem, 4rem);
  z-index: 101;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.5s;

}
.coffeeHandle{
  width: 1rem;
  height: 1rem;
  border-radius: 5rem;
  border: 0.5rem solid #b89f9f;
  position: absolute;
  transform: translate(1.2rem, 0rem);
  /* -o-transform: translate(4.3rem, 0rem); */
  /* -webkit-transform: translate(4.3rem, 0rem); */
  z-index: 101;
}
.coffeeCup:hover{
  transition: 0.5s;
  filter: brightness(60%);
  width: 6rem;
  height: 5rem;
}
.steam{
  width: 3rem;
  height: 3rem;
  opacity: 50%;
  border-radius: 5rem;
  background-color: #f0f0f0;
  animation: steaming 3s infinite;
  position: absolute;
}
.steam2{
  width: 2rem;
  height: 2rem;
  opacity: 50%;
  border-radius: 5rem;
  background-color: #f0f0f0;
  animation: steaming 5s infinite;
  position: absolute;
}
.steam3{
  width: 4rem;
  height: 4rem;
  opacity: 50%;
  border-radius: 5rem;
  background-color: #f0f0f0;
  animation: steaming 6s infinite;
  position: absolute;
}
@keyframes steaming {
  0%{
    transform: translate(0rem, 3rem);
    opacity: 50;
  }
  100%{
    transform: translate(1rem, -2rem);
    opacity: 0;
  }
}
.monitor{
  width: 20rem;
  height: 10rem;
  /* background-color: rgb(158, 150, 150); */
  background:
  linear-gradient(rgb(9, 2, 51), transparent),
  linear-gradient(to top, rgb(45, 37, 87), transparent),
  linear-gradient(to bottom, rgb(9, 2, 51), transparent);
  border: 0.5rem solid #ffffff;
  border-radius: 1rem;
  transform: translate(0rem, 1rem);
  animation: resize 4s infinite;
  z-index: 100;
  margin: 1rem;
}
.monitorStand{
  width: 1rem;
  height: 5rem;
  background-color: #ffffff;
  /* border-radius: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  animation: resizeStand 4s infinite;
  z-index: 90;
}
.monitorStand2{
  width: 10rem;
  height: 1rem;
  background-color: #ffffff;
  border-radius: 2rem;
  animation: resizeStand2 4s infinite;
  z-index: 90;
}
@keyframes resize {
  0%{
    width: 20rem;
    height: 10rem;
  }
  20%{
    width: 11rem;
    height: 8rem;
  }
  40%{
    width: 5rem;
    height: 12rem;
  }
  60%{
    width: 2rem;
    height: 5rem;
  }
  80%{
    width: 15rem;
    height: 12rem;
  }
}

@keyframes resizeStand{
  0%{
    width: 1rem;
    height: 5rem;
  }
  40%{
    width: 0rem;
    height: 0rem;
  }
  80%{
    width: 0rem;
    height: 0rem;
  }
}
@keyframes resizeStand2{
  0%{
    width: 10rem;
    height: 1rem;
  }
  40%{
    width: 0rem;
    height: 0rem;
  }
  80%{
    width: 0rem;
    height: 0rem;
  }
}

.animatedChatBox{
  width: 20rem;
  height: 20rem;
  /* background-color: #0055ff; */
  border-radius: 1rem;
  background:
    linear-gradient(rgb(3, 95, 216), transparent),
    linear-gradient(to top, rgb(3, 33, 63), transparent),
    linear-gradient(to bottom, rgb(3, 50, 88), transparent);
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; */
  padding: 1rem;
}
.animateMessage1{
  width: 10rem;
  height: 2rem;
  background-color: #f8f8f8;
  border-radius: 1rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
.animateMessage2{
  width: 10rem;
  height: 2rem;
  background-color: #4a83ec;
  border-radius: 1rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
.animatedMessageContainer{
  display: flex;
  align-items: center;
  margin: 1rem;
}
.animatedMessageContainer.left{
  justify-content: flex-start;
}
.animatedMessageContainer.right{
  justify-content: flex-end;
}
.messageSent1{
  animation: messageSent 4s infinite;
}
.messageSent2{
  animation: messageSent 4s infinite;
}
.messageSent3{
  animation: messageSent2 4s infinite;
}
.messageSent4{
  animation: messageSent2 4s infinite;
}
.messageSent5{
  animation: messageSent2 4s infinite;
}
@keyframes messageSent {
  0%{
    width: 0rem;
    height: 0rem;
    font-size: 0rem;
  }
  50%{
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    opacity: 100;
  }
  100%{
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    opacity: 0;
  }
}
@keyframes messageSent2 {
  0%{
    width: 0rem;
    height: 0rem;
    font-size: 0rem;
  }
  25%{
    width: 0rem;
    height: 0rem;
    font-size: 0rem;
  }
  50%{
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    opacity: 100;
  }
  100%{
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    opacity: 0;
  }
}

.phonePart{
  width: 3rem;
  height: 15rem;
  background-color: #00ff6a;
  border-radius: 2rem;
  transform: rotate(-40deg);
}
.phonePart2{
  width: 6rem;
  height: 5rem;
  background-color: #00ff6a;
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transform: translate(0rem, -1rem);
}
.phonePart2.bottom{
  width: 6rem;
  height: 5rem;
  background-color: #00ff6a;
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transform: translate(0rem, 6rem);
}
.animatedPointer{
  /* width: 4rem;
  height: 4rem; */
  /* background-color: #fff; */
  border: 0.5rem solid #000;
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: flex-end;

  border-color: transparent transparent rgb(255, 255, 255) transparent;
  border-style: solid;
  border-width: 0px 5rem 8rem 5rem;
  height: 0px;
  width: 0px;
  transform: rotate(-40deg);
  animation: zoomClick 1s infinite;
}
.animatedPointerHandle{
  width: 2rem;
  height: 8rem;
  background-color: rgb(255, 255, 255);
  transform: rotate(-40deg) translate(-1rem, 5rem);
  animation: zoomClick2 1s infinite;
}
@keyframes zoomClick {
  0%{
    border-width: 0px 5rem 8rem 5rem;
  }
  50%{
    border-width: 0px 4rem 7rem 4rem;
  }
  100%{
    border-width: 0px 4rem 7rem 4rem;
  }
}
@keyframes zoomClick2 {
  0%{
    width: 2rem;
    height: 8rem;
  }
  50%{
    width: 1rem;
    height: 7rem;
  }
  100%{
    width: 1rem;
    height: 7rem;
  }
} 





/*App.js*/
/* [draggable=true]{
  cursor: move;
} */
Draggable{
  z-index: 100 !important;
}
.forumPage{
  min-height: 100vh;
}

.musicPlayer{
  position: fixed;
  top: 8rem;
  resize: both;
  max-width: 30rem;
  max-height: 46rem;
  /* border-left: 0.1rem solid black;
  border-right: 0.1rem solid black;
  border-top: 0.1rem solid rgb(54, 112, 219);
  border-bottom: 0.1rem solid rgb(54, 112, 219); */
  min-width: 50px;
  min-height: 50px;
  background-color: rgb(54, 112, 219);
  padding: 0.1rem;
}

.memberCard{
  position: absolute;
  top: 30vh;
  left: 1vh;
  width: 100%;
  max-width: 10rem;
  height: auto;
  /* overflow-y: scroll; */
}
.memberCard.right{
  left: 10vh;
}
.navigationBar{
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: auto;
  /* background-color: #0074D9; */
  background-color: #F2F0E0;
  top: 0rem;
  right: 0;
  padding: 1rem;
  transform: translate(0rem, 5vh);
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  animation: slideToLeft 0.5s;
  transition: 0.5s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navigationBar.close{
  transform: translate(90vw, 5vh);
  transition: 0.5s;
}

@keyframes slideToLeft {
  0%{
    right: -100rem;
  }
  100%{
    right: 0;
  }
}
@keyframes slideToRight {
  0%{
    left: -100rem;
  }
  100%{
    left: 0;
  }
}
.fa-bars{
  z-index: 2000;
  color: #000;
}
.fa-xmark{
  z-index: 2000;
  color: #000;
}
.addFriendButton{
  position: absolute;
  transform: translate(10rem, 0rem);
  background-color: #7e9ddb;
  padding: 1rem;
  border-radius: 2rem;
}
.notifButton{
  position: absolute;
  transform: translate(10rem, 2rem);
  background-color: #7e9ddb;
  padding: 1rem;
  border-radius: 2rem;
}
.notifButton.right{
  position: absolute;
  transform: translate(15rem, 2rem);
  background-color: #7e9ddb;
  padding: 1rem;
  border-radius: 2rem;
}
.fa-bell{
  color: rgb(207, 182, 42);
}

.notificationDropdown{
  visibility: hidden;
  background: #0055ff;
  width: 20rem;
  height: 20em;
  overflow-y: scroll;
  position: absolute;
  padding: 1rem;
  color: #fff;
  border-radius: 2rem;
  transition: 0.1s;
  opacity: 0;
}
.notificationDropdown.mobile{
  visibility: visible;
  background: #0055ff;
  width: 20rem;
  height: 20em;
  overflow-y: scroll;
  position: absolute;
  padding: 1rem;
  color: #fff;
  border-radius: 2rem;
  transition: 1s;
  opacity: 0;

}
.notificationDropdown > li{
  background-color: #0074D9;
  border-radius: 2rem;
  padding: 1rem;
}

.bell:hover .notificationDropdown{
  visibility: visible;
  transition: 1s;
  opacity: 100;
}

.accumulate{
  background-color: transparent;
  color: grey;
  font-size: 2rem;
}

.accumulate.pressed{
  color: orange;
}

.titleField{
  width: 100%;
  text-align: left;
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 1rem;
  color: #fff;
}

.tagSelect{
  width: 20rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  border: 0.1rem solid rgb(105, 103, 103);
}

.tagSelectItem{
  font-size: 1.2rem;
  height: 1.2rem;
  width: auto;
  border-radius: 2rem;
  white-space: nowrap;
  padding: 1rem;
}

.autoComplete{
  /* top: 0; */
  position: absolute;
  width: 20rem;
  height: auto;
  background-color: #fff;
  /* transform: translate(0rem, 40vh); */
  z-index: 10;
  padding: 1rem;
  color: #000;
  margin-top: 15vh;
}
.autoComplete > div {
  padding-top: 1rem;
  width: 100%;
  border-bottom: 0.1rem solid #000;
}

.clickableIcon{
  border-radius: 50%;
  background-color: #0074D9;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.clickableIcon.slimRectangle{
  background-color: grey;
  opacity: 50%;
  border-radius: 10%;
  width: 1rem;
  height: 3rem;
  /* height: 100%; */
}

.loadCommentButton{
  background-color: #0d1c2b;
  border-radius: 2rem;
  width: 20rem;
  height: 5rem;
  line-height: 5rem;
  /* position: fixed; */
  /* bottom: 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.statDiv{
  max-width: 25rem;
}
.statDiv.bar{
  max-width: 50rem;
}
/* .statDiv.bar > canvas {
  position: absolute;
  pointer-events:none;
} */
























































/*PHONE*/
.keyNum{
  width: 100%;
  max-width: 5rem;
  min-width: 1rem;
  height: 5rem;
  color: #fff;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: calc(32% - 0.1rem);
  margin: 0.5rem;
  background: transparent;
}

.keyNum:hover{
  transition: 0.2s;
  color: #673ab7;
  background-color: #fff;
  border-radius: 2rem;
}

.keyNum:active{
  transition: 0.2s;
  color: #fff;
  background-color: #38127a;
}

.keyBackspace{
  height: 14vh;
  max-width: 14vh;
}

.keyPad{
  background-color: #673ab7;
  /* opacity: 75%; */
  width: 20rem;
  border: 1px solid black;
  display: inline-block;
  height: 40rem;
  min-width: 10rem;
  max-width: 35rem;
  background:
      linear-gradient(rgb(111, 0, 255), transparent),
      linear-gradient(to top left, lime, transparent),
      linear-gradient(to top right, rgb(0, 140, 255), transparent);
  background-blend-mode: screen;
  opacity: 100%;
  position: fixed;
  bottom: 0;
  animation: slideToRight 0.2s;
  font-size: 1rem !important;
}


.spectrum-background {
  background:
      linear-gradient(red, transparent),
      linear-gradient(to top left, lime, transparent),
      linear-gradient(to top right, blue, transparent);
  background-blend-mode: screen;
}

.keyRow{
  width: 100%;
  text-align: center;
  min-width: 20rem;
  font-size: 0.5rem;
}

.keyContent{
  padding: 1rem;
  font-size: 2rem;
}

.keyContent.sub{
  padding: 0.05rem;
  font-size: 0.5rem !important;
}


.callButton{
  border: 0rem;
  border-radius: 10rem;
  background-color: #14ad21;
  color: #fff;
  width: 100%;
  max-width: 10vh;
  min-width: 2rem;
  height: 6vh;
  font-size: 3vh;
}

.callButton:hover{
  background-color: #94d699;
  transition: 0.2s;
  color: #14ad21;
  border-radius: 1rem;
  border-radius: 1rem;
}

.callButton:active{
  background-color: #94d699;
  transition: 0.2s;
  color: #14ad21;
}

.padding{
  padding-bottom: 1rem;
}

.emptySpace{
  height: 20rem;
}


input.inputBox{
  background-color: #fff;
  padding: 4vh;
  border-radius: 4vh;
  width: 100%;
  box-sizing:border-box;
  font-size: 3vh;
}

input{
  padding: 1vh;
  width: 20vh;
  height: 2vh;
  font-size: 3vh;
  margin: 1vh;
  text-align: center;
  border: 0rem;
  background-color: transparent;
}

input.phoneNum {
  text-shadow: 0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21,
               0 0 50px #14ad21;
  color: #fff;
  font-size: 3rem;
  width: 15rem;
  height: 1rem;
}

input.inputBoxS{
  background-color: #fff;
  padding: 4vh;
  border-radius: 4vh;
  width: 100%;
  box-sizing:border-box;
  font-size: 2vh;
}

input.searchBar{
  background-color: #fff;
  width: 100%;
  border-radius: 1vh;
  border: 0rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.optionBtn{
  width: 100%;
  color: #fff;
  background-color: transparent;
  border: 0;
  font-size: large;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: calc(32% - 1rem);
  height: 14vh;
  background: transparent;
  width: 14vh;
  text-align: center;
}

.optionBtn:hover{
  background-color: #ffffff;
  transition: 0.2s;
  color: #2d296d;
  border-radius: 1rem;
}

.contentRow{
  padding: 2vh;
  color: #fff;
  font-size: 4vh;
  height: 4vh;
}

.contentRow.displayNumber{
  padding: 3rem;
  color: #fff;
  font-size: 4vh;
}

.keyPad.calling{
  height: 100vh;
}

.keyNum.round{
  border: 0.2rem solid rgb(150, 145, 145);
  border-radius: 5rem;
  width: 3rem;
  height: 3rem;
  font-size: 0.2rem !important;
  text-align: center;
}

.keyNum.round:hover{
  background-color: #fff;
  transition: 0.1s;
  border: 0rem;
}

.popup{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9000;
  padding: 3vh 3vh 3vh 3vh;
  color: #fff;
  border-radius: 2vh;
  font-size: 3vh;
  width: 30vh;
  position: absolute;
  opacity: 100%;
  background:
    linear-gradient(rgb(10, 66, 139), transparent),
    linear-gradient(to top, rgb(50, 122, 218), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  animation: zoomPopup 0.7s;
}

.popup.info{
  background-color:rgb(43, 110, 197);
  padding: 0vh 3vh 3vh 3vh;
}

.popup.error{
  background-color:rgb(182, 70, 62);
}

.popup.option{
  background-color:rgb(43, 110, 197);
  padding: 0vh 3vh 3vh 3vh;
}

.popup.hangup{
  background-color: rgb(180, 35, 35);
}
.popupBackground{
  backdrop-filter: brightness(50%);
  width: 100vw;
  height: 100vh;
  z-index: 2500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
}

@keyframes zoomPopup {
  0%{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1); 
    opacity: 50%; 
  }
  50%{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.4);  
    opacity: 80%;
  }
}

@keyframes slideUpPopup {
  from { bottom: 0; }
  to {
    top: 50%;
    left: 50%;
    transition: 0.5s;
    transform: translateX(50%);
  }
}

.phoneConfirmBtn{
  border: 0rem;
  background-color: rgb(4, 255, 129);
  color: #000000;
  padding: 2vh 4vh 2vh 4vh;
  border-radius: 2vh;
  font: 1rem solid;
  margin-top: 2vh;
  width: 20vh;
}

.phoneConfirmBtn:hover{
  transition: 0.2s;
  background-color: rgb(1, 148, 74);
  color: #353535;
  box-shadow: #353535 2rem;
}

.phoneConfirmBtn.xClose{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 0, 0);
  color: #ffffff;
  padding: 2rem;
  border-radius: 5rem;
  font: 2rem solid;
  transform: translate(20rem, -3rem);
  width: 4rem;
  height: 4rem;
}

.phoneConfirmBtn.info{
  background-color: rgb(4, 255, 129);
  color: #fff;
}

.phoneConfirmBtn.info:hover{
  background-color: rgb(1, 146, 74);
  color: #fff;
}

/*From Popup Component reactjs-popup*/
.popup-content {
  margin: auto;
  background: transparent !important;
  border: 0rem !important;
  width: auto !important;
  padding: 0rem !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.callButton.red{
  background-color: #ff0000;
  color: #fff;
}

.callButton.dial{
  width: 4rem;
  height: 4rem;
}

.callButton.dial > i{
  font-size: 2rem;
}

.hangupIcon{
  transform: rotate(135deg);
}

.homeBackground{
  text-align: center;
  background-color: #38127a;
}

.invisi{
  visibility: hidden;
}

.popupCoverup{
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: absolute;
  opacity: 50%;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
}

button.submitBtn{
  border-radius: 4vh;
  width: 100%;
  height: 10vh;
  background-color: #673ab7;
  color: #fff;
  font-size: 5vh;
  border: 0rem;
}

button.submitBtn:hover{
  border-radius: 4vh;
  background-color: #fff;
  color: #673ab7;
  transition: 0.2s;
}

.scrollable{
  overflow-y: scroll;
}



.fixedHeight{
  height: 10vh !important;
}

.phoneConfirmBtn.back{
  color: #fff;
  background-color: #8a4ef3;
}

.phoneConfirmBtn.back:hover{
  color: #fff;
  background-color: #693db6;
}

.timer{
  color: #fff;
  height: 20vh !important;
}

.timer > span{
  font-size: 4vh;
}

.phoneConfirmBtn{
  color: #fff;
}


/*can't use responsive text here. Bigger screen must display more texts*/
.displayNameContact{
  color: #fff;
  font-size: 1.2rem;
}

.displayNumberContact{
  color: #fff;
  font-size: 1rem;
}

.scrollableDiv{
  overflow-y: scroll;
  height: 70vh;       
  overflow-y: auto;    
  overflow-x: hidden;
  width: 100%;
}

.scrollableDiv.full{
  overflow-y: scroll;
  height: 94vh;       
  overflow-y: auto;    
  overflow-x: hidden;
  width: 100%;
}

.callStartedIcon{
  color: #fff;
}

.callStartedIcon > .fa-arrow-up{
  color:rgb(45, 235, 140);
  font-size: 0.7rem;
  transform: rotate(45deg);

}

.callReceivedIcon> .fa-arrow-up{
  color:rgb(45, 235, 140);
  transform: translate(2rem, -2rem);
  transform: rotate(-145deg);
}

.callNotPickedUpIcon> .fa-ban{
  color:#ff0000;
  font-size: 0.7rem;

}

.callNoData> .fa-question{
  color: #ffc815;
  font-size: 0.7rem;
}

.toolTip{
  width: 10rem;
  height: 2rem;
  color:#000000;
  background-color: #fff;
  visibility: hidden;
  border: solid 1rem;
}

.contactMenuBtn{
  width: 4vh;
  color: #fff;
  height: 4vh;
  border-radius: 2vh;
  font-size: 2vh;
  background-color: #14ad21;
  border: 0rem;
}

.contactMenuBtn:hover{
  filter: brightness(50%);
  transition: 0.2s;
  border-radius: 1vh;
}

.contactMenuBtn.remove{
  background: #ff0000;
}

.contactMenuBtn.edit{
  background: #00273d;
}

.default{
  border-top: 1px solid rgb(8, 120, 224);
  width: 80%;
}

.pan{
  height: 30vh;
}

.titleCardContainer{
  perspective: 100rem;
  position: fixed;
  animation: titleCard 2s 2s;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.titleCard{
  position: relative;
  /* font-size: 10rem; */
  transform: rotateX(25deg);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  box-shadow: 5px 6px 6px 2px #020202;
  font-style: bold;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  backdrop-filter: blur(10rem);
  /* visibility: hidden; */
  width: auto;
  justify-content: center;
  display: flex;
  /* background-color: aqua; */
}

@keyframes titleCard {
  0% {
    bottom: 0;
    font-size: 1rem;
    color: rgb(238, 255, 0);
    visibility: visible;
  }
  80% {
    bottom: 40rem;
    font-size: 10rem;
    color: rgb(238, 255, 0);
    visibility: visible;
  }
  
  81% {
    bottom: 40rem;
    font-size: 10rem;
    color: rgb(238, 255, 0);
    visibility: visible;
  }
  100% {
    bottom: 40rem;
    font-size: 10rem;
    color: red;
    transition: 0.2s;
    visibility: hidden;
  }
}

.avatarHat{
  position: absolute;
  /* transform: translate(3rem, -6rem); */
  transform: translate(3rem, -3rem);
}

.fa-crown{
  color: yellow;
  /* text-shadow: -0.5rem -1px 0 #000, 0.5rem -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
}

.avatarDeco{
  transform: translate(0rem,6.5rem);
  position: absolute;
  font-size: 20rem;
  /*outside userProfile:*/
  /* transform: translate(49rem,-2rem); */

  /*inside userProfile*/
  opacity: 50%;
  /* width: 20rem;
  height: 20rem; */
  width: auto;
  height: auto;
  transform-origin: center center;
}

.fa-sun{
  animation: spin 10s linear infinite, grownshrink 1s linear infinite;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url("/public/assets/showcase/galaxy_demo.png");
  filter: brightness(20);
}

@keyframes spin {
  0% {
    font-size: 20rem;
    transform: rotate(0);
  }
  25%{
    font-size: 25rem;
  }
  50%{
    font-size: 20rem;
  }
  75%{
    font-size: 25rem;
  }
  100% {
    font-size: 20rem;
    transform: rotate(360deg);
  }
}

@keyframes grownshrink {
  0% {
    font-size: 20rem;
  }
  25%{
    font-size: 25rem;
  }
  50%{
    font-size: 20rem;
  }
  75%{
    font-size: 25rem;
  }
  100% {
    font-size: 20rem;
  }
}

.itemLink{
  height: 1rem;
  padding: 1vh 2vw 1vh 2vw;
}

.itemLink:hover{
  /* color: #fff; */
  border-radius: 0.5rem;
  border-left: solid 0.5rem #b1ac97;
  border-right: solid 0.5rem #b1ac97;
}
.itemLink.selected{
  /* color: #fff; */
  border-radius: 0.5rem;
  border-left: solid 0.5rem #b1ac97;
  border-right: solid 0.5rem #b1ac97;
}

.albumTitle{
  /* background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  padding: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom: solid 0.2rem #F2F0E0;
  border-left: solid 0.2rem #F2F0E0;
  border-right: solid 0.2rem #F2F0E0;
  font-size: 5vw;
}

.stickyBookmark{
  top: -1px;
  z-index: 100;
  position: -webkit-sticky;
  /* height: 1vh; */
  position: sticky;
  display: block;
  backdrop-filter: blur(1rem);
  padding-top: 4rem;
}

.stickyBookmark.sticked{
  top: -1px;
  z-index: 100;
  position: -webkit-sticky;
  /* height: 1vh; */
  position: sticky;
  background-color: #F2F0E0;
  color: #000;
}

.invisible{
  visibility: hidden;
}

.no-select{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.globalBackgroundMusicPlayer {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom: solid 0.2rem #F2F0E0;
  border-left: solid 0.2rem #F2F0E0;
  border-right: solid 0.2rem #F2F0E0;
  background-color: #F2F0E0;
  color: #000;
  /* padding-top:  2rem; */
  position: absolute;
  width: 100%;
  max-width: 15rem;
}